import React, {useEffect} from 'react'
import { fetchAllSCoupons, scouponsSelector } from '../../api/sCouponSlice'
import {fetchAllBrands, brandSelector} from '../../api/brandSlice'
import {loginSelector,fethFilter} from '../../api/authSlice'
import { Form, Input, Button, Modal,  Checkbox } from 'antd';
import {  useDispatch, useSelector } from 'react-redux';
import Loader from '../../shared/loader'
import Brand from './scoupon'
import { Link } from 'react-router-dom';


export default function Index() {
    const dispatch = useDispatch()

    const { brands, loading } = useSelector(brandSelector)
    const { sAllCoupons } = useSelector(scouponsSelector)
    const {filter} = useSelector(loginSelector)
    console.log({k:filter})
    // console.log(sAllCoupons);

    useEffect(()=>{
        dispatch(fetchAllBrands())
        dispatch(fetchAllSCoupons())
    }, [dispatch])

    let pbrands = brands?.filter(item => item.hasPriority === true).sort((a,b)=>a.priority - b.priority)
    
    
console.log(brands);
    
    return (
        <div className="container mt-5 pb-4">
                <h2>E-Vouchers</h2>
            <hr/>
            {
                loading? <Loader/> : <Brand brands={(filter?.length > 0) ? filter : pbrands} sAllCoupons={sAllCoupons}/>
            }

 
        <Button 
         style={{backgroundColor:'white',
          border:'1px solid #9a4c9d ', 
          color:'#9a4c9d', fontSize:"1rem",
           float:"right",
      
        }}
          type="primary">
              <Link to="/e-vouchers">
          View More
          </Link>
       </Button>
 <br></br>
 <br></br>

        </div>
    )
}
