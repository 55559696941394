import { createSlice } from '@reduxjs/toolkit';
import keyUri from '../key'

const cart =  localStorage.getItem('cart') ?
   JSON.parse(localStorage.getItem('cart')) : []

const total =  localStorage.getItem('total') ?
   JSON.parse(localStorage.getItem('total')) : 0

   

export const initialState = {

    loading: false,
    hasErrors: false,
    cartCoupons:cart,
    subTotal:0,
    total: Number(total),
    isUsedWalletBallance : 0,
    isUsedWallet : false
      


}

export const cartSlice = createSlice({
    name:"cart",
    initialState,
    reducers:{

        getCartCoupons: state =>{

        state.loading = true
        },
        
      getTotal: state =>{
         
        state.total = state.cartCoupons.reduce((total, crr)=>{

            return total + (crr.value * crr.qty)

        }, 0)

      },
      removeItem: (state, {payload}) => {

        state.cartCoupons = state.cartCoupons.filter(item => {

            return item._id !== payload
        }) 
        state.total = state.cartCoupons.reduce((total, crr)=>{

            return total + (crr.value * crr.qty)

        }, 0)

        localStorage.setItem('cart', JSON.stringify(state.cartCoupons) )
        localStorage.setItem('total', JSON.stringify(state.total) )

      },



        getCartCouponSuccess : (state, {payload}) => {

        console.log(payload);

       const exist = state.cartCoupons.findIndex((item) => item._id === payload._id);
        if(exist !== -1){

            state.cartCoupons[exist].qty = state.cartCoupons[exist].qty + 1

        }else{

            state.cartCoupons =[...state.cartCoupons, payload] 

        }
            state.loading = false
            state.hasErrors = false
            state.total = state.cartCoupons.reduce((total, crr)=>{
                return total + (crr.value * crr.qty)
            }, 0)
            
            localStorage.setItem('cart', JSON.stringify(state.cartCoupons) )
            localStorage.setItem('total', JSON.stringify(state.total) )

        },


        increments:( state, {payload}) =>{

          state.cartCoupons.map(item=>{

                if(item._id === payload.id && payload.incr === "incr" ){
                   item.qty += 1;
                 
                } else if(item._id === payload.id && payload.decr === "decr"){

                   item.qty -= 1; 
              
                }

                return state.cartCoupons
               
            })
        },

        getWalletBalance:( state, {payload}) =>{
            state.loading = false
            state.isUsedWallet = payload.iswallet
            state.isUsedWalletBallance = payload.b

    

        } ,
      
        getCartCouponsFailure : state =>{

            state.loading = false
            state.hasErrors = true 
        },

      

    }
})

export const {
    getCartCouponsFailure,
    getCartCouponSuccess,
    getCartCoupons,
    increments,
    getTotal,
    removeItem,
    getWalletBalance
} = cartSlice.actions

export const cartCouponSelector = state => state.cart
export default cartSlice.reducer


export const addCartCoupons = (data) => async dispatch =>{
    dispatch(getCartCoupons())
    
    try {
       
        dispatch(getCartCouponSuccess(data))
          
    } catch (error) {
        dispatch(getCartCouponsFailure())
  
    }
}

export const qtyupdate = (id) => async dispatch =>{

    dispatch(increments(id))
    dispatch(getTotal())

}
export const deleteCartItem = (id) => async dispatch =>{

    dispatch(removeItem(id))
 

}



export const fetchWalletbalance = (bal) => async dispatch =>{

    dispatch(getWalletBalance(bal))

}

