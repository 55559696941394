import React, { useState } from 'react'
import styled from 'styled-components'
import {useDispatch, useSelector} from 'react-redux'
import { checkOutSelector } from '../../api/checkOutSlice'
import { cartCouponSelector, fetchWalletbalance } from '../../api/cartSlice'
import {loginSelector} from '../../api/authSlice'
import { MdCardGiftcard } from "react-icons/md";
import Payment from './payment'
import { Checkbox } from 'antd';

export default function PaymentMethod() {


    const dispatch = useDispatch()
    const { loading,  shippingAdress, vMethod  } = useSelector(checkOutSelector)
    const {   cartCoupons, total, isUsedWallet, isUsedWalletBallance  } = useSelector(cartCouponSelector)
    const {curr_user, balance} = useSelector(loginSelector)

    const [c_balance, setC_Balance] = useState(total)

    let b = balance - c_balance


    console.log(shippingAdress);

    
    const onChange = (e) => {

        let isChecked = e.target.checked


        
        if( isChecked) {

            if( b > 0){


                
                dispatch(fetchWalletbalance({iswallet:true, b:Math.abs(b)})) 
                  return  setC_Balance(0)


            } else {

                 dispatch(fetchWalletbalance({iswallet:true, b:Math.abs(b)})) 
                 return  setC_Balance(Math.abs(b))

            }
         

        } else {

                dispatch(fetchWalletbalance({iswallet:false, b:0})) 

                   setC_Balance(total)

            
        }


      }
      


    return (
        <PlaceOrderWrap className="my-4">

        <div className="row">
            <div className="col-sm-8">

        <div className="pl">
            <h5 style={{color:"#AF7C5C"}}>Shipping</h5>

            <h6>{shippingAdress?.email} <br/> {shippingAdress?.phone_number}<br/>{shippingAdress?.address}</h6>

        </div>
        <hr/>

        {/* <div className="pl"> */}
            {/* <h5 style={{color:"#AF7C5C"}}>Voucher Type</h5> */}

            {/* <h6>{vMethod}</h6> */}
        {/* </div> */}
        {/* <hr/> */}

        <div className="pl">
            <h5 style={{color:"#AF7C5C"}}>Order Items</h5>

        <div className="container-fluid my-2">

    {
       cartCoupons.map((item, i)=>{

        return <div className="row align-items-center shadow-sm my-3 py-2" key={i}>
      
       <div className="col"><MdCardGiftcard className="bg-dark voucher"/>
       <span className="text-dark" style={{fontSize:'1rem'}}><b>{item.name}</b></span>
       </div>
       
      <div className="col">
          <h6>{item.qty}&nbsp; x &nbsp;&#x20B9;&nbsp;{item.value} &nbsp;= &nbsp;&nbsp;&#x20B9;&nbsp;{(item.qty * item.value)}</h6> 
            </div>
      

        </div>
       }) 
    }
</div>
    

   
</div>


    </div>
    <div className="col-sm-4">

    <div className="card">
  <div className="card-header">
    <h5>Order Summery</h5>
  </div>
  <ul className="list-group list-group-flush">
    <li className="list-group-item  d-flex align-items-center justify-content-between">
        <h6>Items</h6>
        <h6>&#x20B9;&nbsp;{total}</h6>

         </li>
    <li className="list-group-item d-flex align-items-center justify-content-between">
     <h6>Shipping</h6>
        <h6>&#x20B9; 0/-</h6>   
    </li>
    <li className="list-group-item d-flex align-items-center justify-content-between">
             <h6>Tax</h6>
        <h6>&#x20B9; 0/-</h6> 
    </li>
    <li className="list-group-item d-flex align-items-center justify-content-between">
             <h6>Total</h6>
        <h6>&#x20B9;&nbsp;{total}</h6> 
    </li>
    <li className="list-group-item d-flex align-items-center justify-content-between">
            <button className="btn btn-dark w-100">
               <Payment
                info={shippingAdress}
                user={curr_user}
                cart={cartCoupons}
               total={total}
               vMethod={vMethod}
               payableAmt={isUsedWalletBallance > 0 ? c_balance : c_balance}
              wallet ={isUsedWallet}
               />
            </button>
    </li>

 
  </ul>


  <div className="px-3 py-2">

    <Checkbox  disabled ={(balance > 0) ? false : true } onChange={onChange}>Use Luova Wallet</Checkbox>

    {
        isUsedWallet && <h6 className="pl-4 mt-2">Payable Amount : 	&#8377; {c_balance}</h6>
    }

    {
        isUsedWallet && <h6 className="pl-4 mt-2">Availaible bal : 	&#8377; { (c_balance > 0) ? 0 : isUsedWalletBallance}</h6>
    }


</div>
</div>
</div>
</div>
           
        </PlaceOrderWrap>
    )
}

const PlaceOrderWrap = styled.div`
.voucher{
font-size:2rem;
color:orange;
.balanceCheck{
}
.pl{
    h5{
        text-transform: uppercase;
        font-weight:400;
    }
    h6{
        font-weight:400;
        color:grey;
    }
}

}
`