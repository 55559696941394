import React from 'react'
import { Drawer, Space, Button, Image } from 'antd';
import { qtyupdate, cartCouponSelector, deleteCartItem } from '../../api/cartSlice'
import {useDispatch, useSelector} from 'react-redux'
import styled from 'styled-components'
import { MdCardGiftcard, MdClose } from "react-icons/md";
import {showModal, loginSelector } from '../../api/authSlice'
import {viewCartshowModal, modalSelector} from '../../api/modalSlice'
import emptycartImg from '../../images/empty-cart.jpg'
import {Link, Redirect} from 'react-router-dom'
// import {viewCartshowModal, modalSelector} from '../../api/modalSlice'
import Loader from '../../shared/loader'

export default function CartView(props) {


  const dispatch = useDispatch()

const cart = useSelector(cartCouponSelector)
const {isAuthenticate, logpopUp } = useSelector(loginSelector)
const { visibleViewcart} = useSelector(modalSelector)


const increment = (id) =>{
  let data = {id, incr:'incr'}
  dispatch(qtyupdate(data))
}

const decrement = (id) =>{
  let data = {id, decr:'decr'} 
  dispatch(qtyupdate(data))
}



const handleClick = () =>{
  dispatch(viewCartshowModal(false)) 
  logpopUp? dispatch(viewCartshowModal(false))
  : dispatch(showModal(true))



}


    return (
        <>
              <Drawer

          width="650px"
          title="View Cart"
          placement="right"
          closable={false}
          onClose={()=>dispatch(viewCartshowModal(false))}
          visible={visibleViewcart}
          key="right"
        >
    {cart.cartCoupons.length > 0 ?  <CartWrap>
          <div style={{backgroundColor:"#9a4c9d"}} >
            <div className="row px-2 text-light align-items-center" >

              <div className="col-5"><h6 className="text-light py-2 mb-0">Voucher</h6></div>
              <div className="col-2"><h6 className="text-light py-2 mb-0">Price</h6></div>
              <div className="col-4"><h6 className="text-light py-2 mb-0">Quantity</h6></div>
              
            </div>
          </div>
          {
              cart.cartCoupons.map((item, i)=>{

                return  <div key={i} className="row text-light align-items-center  shadow-sm my-3 py-1">
                     <div className="col-5">
                     <MdCardGiftcard className="bg-dark voucher"/>&nbsp;
                      {/* {item.type === 'coupon' ? <MdCardGiftcard className="bg-dark voucher"/>
                       : 
                        <Image    
                       preview={false}               
                       style={{margin:'0px',height:"45px", width:"100%", display:'block'}}
                       src={ item?.image}
                       placeholder='image'              
                       /> 
                      }&nbsp; */}
                      
                       <small className="text-dark my-auto pl-1 px-auto " style={{fontSize:'0.75rem' , textTransform:"capitalize"}}><b>{item.name}</b></small>
                       </div>
                    

        
                     <div className="col-2"><h6>&#x20B9; {item.value}</h6></div>
                     <div className="col-4">
                   <Space>
                     <Button disabled={(item.qty === 1)? true : false} type="primary" onClick={()=>decrement(item._id)}>-</Button>
                     <Button>{item.qty}</Button>
                     <Button type="primary" onClick={()=>increment(item._id)}>+</Button>
        
                   </Space>
                   </div>
                   <div className="col-1">
                       <MdClose onClick={()=>dispatch(deleteCartItem(item._id))} className="close "/>
                       </div>
                 
                </div>
              })
          }
       
       <div className="Amount mt-5">


       <div className="row">

        <div className="col-sm-9 text-light"><h6>{cart.cartCoupons.length}&nbsp; Quantity</h6></div>
        
        <div className="col-sm-3 text-light"><h6>&#x20B9; {cart.total}&nbsp; </h6></div>


       </div>


       </div>
       {isAuthenticate?
        <Link  className="btn btn-warning float-right my-3" to="/checkout">
          Proceed to CheckOut</Link> :
         <button onClick={handleClick} className="btn  float-right my-3 shadow-md" 
         style={{color:'#9a4c9d', backgroundColor:'#9a4c9d29', fontWeight:'500'}}>Login</button>}
      

       </CartWrap> : <img src={emptycartImg} alt="img" width="100%"/>  }
        </Drawer> 
        </>
    )
}

const CartWrap = styled.div`

.voucher{

  font-size:2.5rem;
  padding:0.3rem;
  color:orange;
}

.close {

  font-size:2rem;
  color:red;
  /* background-color:red; */
  cursor: pointer;
  display:block;
  margin:auto;
}

.Amount{
padding:0.5rem 1rem;

  background-color:#f5f5f5;

  h6{

    color:#9a4c9d !important;
    margin:0;
  }
 
}



`