import React, {useEffect,useState} from 'react'
import styled from 'styled-components'
import {Link, NavLink} from 'react-router-dom'
import AddtoCoupon from '../components/home/addCoupon'
import Logo from '../images/logo.png'
import { Input  } from 'antd';
import {loginSelector,fethFilter} from '../api/authSlice'
import {useDispatch, useSelector} from 'react-redux'
import { useDebounce } from "use-debounce";
import {useHistory} from 'react-router-dom'


export default function Navbar(props) {

    const dispatch = useDispatch()
    const history = useHistory()

const navbar = [
  {

        title:"Home",
        path:"/home"
 },


{
    title:"E-Vouchers",
    path:"/e-vouchers"
},

// {
//     title:"Products & Gifts",
//     path:"/products-gifts"
// },

{
    title:"Contact",
    path:"/contact"
}


]

const { Search } = Input;
// const { brands } = useSelector(brandSelector)
const {filter} = useSelector(loginSelector)
console.log(filter);

const [search, setSearch] = useState('')
const [loading, setLoading] = useState(false)
const [debouncedText] = useDebounce(search, 1000);

  useEffect(()=>{
      dispatch(fethFilter('brand-admin', debouncedText ))
      history.push('/e-vouchers');
    setLoading(false)
     }, [dispatch, debouncedText])
  
  

//    useEffect(()=>{     
//     if(filter.length < 1) {
//       setSearch('')
//     }
//      }, [filter])

const onSearch = (e) => {
  setLoading(true)
  setSearch(e.target.value)
}



    return (
        <NavbarWrap className=" shadow-sm">
            <div style={{margin:"0.5rem",paddingLeft:"5rem" }}>
         <Link to={'/home'} >
     <img src={Logo} className="d-block mx-auto mt-2 "  width="100px" alt="logo"/>      </Link> </div> 
        
          <nav className="container d-flex justify-content-between align-items-center">
       
    <ul>
           {
               navbar.map((item, i)=>{

               return <li key={i}>
                  <NavLink activeStyle={{color:"rgb(38 3 39)", fontWeight:"500"}} to={item.path}>{item.title}</NavLink> 
               </li>
               })
           }


    </ul>
  
<div  className="searchbutton">
    <Search placeholder="Search E-Vouchers" className="mx-3"  onChange={onSearch} style={{ width: 270 }} enterButton />

    </div>
    <AddtoCoupon />
          </nav>
    
         
        </NavbarWrap>
    )
}

const NavbarWrap = styled.div`

background-color:white;
color: #9a4c9d;
display:flex;
/* align-items:start; */
justify-items: flex-start;
justify-content: flex-start;

.container{
   
    width:100%;
    margin:auto;
    


ul{
    margin:0;
    display:flex;
    padding: 0.8rem;
    align-items:center;
    justify-content:flex-start;

    li{

        list-style:none;
        font-size:1.2rem;
        margin:0 1.3rem;
        a{

            color:#9a4c9d;
        }

    }
}
}
.searchbutton{
.ant-btn {
  color: #fff;
  background: #9a4d9d !important; 
  border-color: #9a4d9d !important;

}
input:focus { 

    border-color: #9a4d9d !important;
   
  
}
input:hover { 

border-color: #9a4d9d !important;

}
}

`