import React,{useEffect, useState} from 'react'
import { Form, Input, Button, Checkbox, message } from 'antd';
import styled from 'styled-components'
// import { Row, Col } from 'antd';
import { fetchLogin,forgetPassword } from '../api/authSlice'
import {  useDispatch } from 'react-redux';



const layout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
  };
  const tailLayout = {
    wrapperCol: { offset: 5, span: 19 },
  };
  


export default function Login() {

  const dispatch = useDispatch()
  const [isemail,setEmail] = useState(null)
  const [visibleEmail, setEmailModal] = useState(false);






  console.log(isemail);


    const onFinish = values => {

      const data = {

      email:values.email.toLowerCase(),
      password:values.password
      }
        dispatch(fetchLogin(data))


      };
    


      const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
      };
     

      const handleChange = (e) =>{

        if(e.target.value.length>0)
        {
        setEmail(e.target.value.toLowerCase())
        } 
        else{
          setEmail(null)
        }
    }


    
    const handleClickEmail = () =>{
      if(!isemail){
        message.error('Email required')
      }
      else{
     
     dispatch(forgetPassword({email:isemail}))
      }
      }


    return (
      <LoginWrap>
       
         <Form
      {...layout}
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      
    >
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input onChange={handleChange}/>
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>


<div style={{textAlign:'right', paddingBottom:'10px', cursor:'pointer'}}>
    <p onClick={handleClickEmail} >Forgot Password?</p>
    </div>
      <Form.Item {...tailLayout}>
        <button className="btn  float-right my-1 shadow-md"  style={{color:'#9a4c9d', backgroundColor:'#9a4c9d29', fontWeight:'500'}} type="primary" htmlType="submit">
          Submit
        </button>
      </Form.Item>
    </Form>
  
   </LoginWrap>
    )
}


const LoginWrap = styled.div`


.ant-form-item {
   
    margin-bottom: 10px !important;
    
}


`