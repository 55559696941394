import React, {useEffect} from 'react'
import { fetchAllSCoupons, scouponsSelector } from '../../api/sCouponSlice'
import {fetchAllBrands, brandSelector} from '../../api/brandSlice'
import {loginSelector,fethFilter} from '../../api/authSlice'
import {  useDispatch, useSelector } from 'react-redux';
import Loader from '../../shared/loader'
import Brand from './scoupon'


export default function Index() {


    const dispatch = useDispatch()

    const { sAllCoupons } = useSelector(scouponsSelector)
    const { brands, loading } = useSelector(brandSelector)
    const {filter,keyword} = useSelector(loginSelector)
    console.log({k:keyword})


    useEffect(()=>{
        dispatch(fetchAllSCoupons())
        dispatch(fetchAllBrands())
    }, [dispatch])

if(keyword ){
    console.log("suraj");
}
    else{
        console.log("kavya");
    }
   
    return (
       
        <div className="container mt-5" style={{minHeight:"70vh"}}>
                <h2>E-Vouchers</h2>
               
            <hr/>
             
           
            {

             loading? <Loader/> : <Brand brands={keyword ? filter : brands} sAllCoupons={sAllCoupons}/> 
        //  loading? <Loader/> : <Brand brands={ filter} sAllCoupons={sAllCoupons}/> 
 
            }
            


      {/* <ViewMore />  */}

     
        
        </div>
       
    )
}
