import React, {useEffect} from 'react'
import Footer from '../global/footer'
import styled from 'styled-components'
import {
    Form,
    Input,
    message,
    Button,

  } from 'antd';

import {fetchUserRegister, loginSelector} from '../api/authSlice'
import { useDispatch, useSelector } from 'react-redux';
const layout = {
labelCol: { span: 5 },
wrapperCol: { span: 16 },
  };



  
export default function RegisterUser({history}) {
    const {isAuthenticate, loading} = useSelector(loginSelector)

    const dispatch = useDispatch()


    useEffect(()=>{

        if (!isAuthenticate) {
            history.push('/user-register');
          }
          else{
    
            history.push('/');
          }
        
    
    }, [isAuthenticate, history])

    

    
    const onFinish = values => {

    console.log(values) 
    
    const data ={
    
    coupon_id:values.coupon_id,
    user_name:values.user_name,
    email:values.email.toLowerCase(),
    password:values.password,
    confirm:values.confirm,
    phone_number:values.phone_number,
    address:values.address

    }
    
    var numbers = /^[0-9]+$/;
    if(values.phone_number && !values.phone_number.match(numbers))
    {
   return message.error("Phone number must be only numbers")
      return true;
    }
    if(values.phone_number.length !== 10){
      message.error("Phone number must be 10 numbers")
    }
      else{
        dispatch(fetchUserRegister(data))
      };
    }

    return (
      <div>
        <div className="container mb-96 mt-3" style={{minHeight:"75vh"}}>

          <h3 className="py-2 text-center text-secondary"> Registration </h3>
             <Form {...layout} 
         
             name="nest-messages"
              onFinish={onFinish} >

                <Form.Item
                  label="Coupon ID"
                  name="coupon_id"
                  rules={[{ required: true, message: 'Please enter Coupon Id!' }]}
                >
                  <Input />
                </Form.Item>

              <Form.Item name='user_name'
              label="Name" 
              rules={[{ required: true,message: 'Username required!' }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'Invalid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please enter your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={['password']}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject('The two passwords that you entered do not match!');
                    },
                  }),
                ]}
              >
                <Input.Password />
              </Form.Item>
          
              
              <Form.Item name='phone_number'
              label="Phone Number" 
              rules={[{ required: true,message: 'Phone Number required!' }]}>
                <Input />
              </Form.Item>

              <Form.Item name="address" label="Address">
                <Input.TextArea />
              </Form.Item>

              <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 5 }}>
                <button className="btn  float-right my-1 shadow-md"  style={{color:'#9a4c9d', backgroundColor:'#9a4c9d29', fontWeight:'500'}} type="primary" htmlType="submit">
                  Register
                </button>
              </Form.Item>
              </Form>
        </div>

       
      </div>
    )
}


