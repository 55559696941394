import React, {useEffect, useState} from 'react'
import { fetchCurrentScoupons, scouponsSelector } from '../../../api/sCouponSlice'
import { addCartCoupons, qtyupdate,  cartCouponSelector } from '../../../api/cartSlice'
import { productSelector, fetchOneProduct} from '../../../api/productSlice'
import Fade from 'react-reveal/Fade'
import {  useDispatch, useSelector } from 'react-redux';
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import { Table, Space, Button, Popover ,Image,Card, Modal} from 'antd';
import Interweave from 'interweave'
import {viewCartshowModal } from '../../../api/modalSlice'
import { MdCardGiftcard, MdAddShoppingCart } from "react-icons/md";
import { BsInfoCircleFill, BsInfoCircle} from "react-icons/bs";
import { Link } from 'react-router-dom';
import Loader from '../../../shared/loader'
import CartView from '../../../components/social_coupons/cartView'
import {useLocation} from 'react-router-dom'
import PButton from './payment'


const { Meta } = Card;


export default function Scdetail(props) {

    const dispatch = useDispatch()
    let { id } = useParams();
    const { pathname } = useLocation()
    const {cartCoupons} = useSelector(cartCouponSelector)

    const {scatgoryCoupon , loading} = useSelector(scouponsSelector)
    const {currentProduct } = useSelector(productSelector)
    const [isVisible, setVisible] = useState(false)
    const [curr_coupon, setCoupon] = useState(null);


console.log(currentProduct)
// console.log(isVisible)


useEffect(()=>{

 dispatch(fetchCurrentScoupons(id))
 dispatch(fetchOneProduct(id))

}, [dispatch])


const onAdd = ( data) =>{
dispatch(addCartCoupons(data))
}


const increment = (id) =>{

    let data = {id, incr:'incr'}
  
    dispatch(qtyupdate(data))
    
  
  }
  
  const decrement = (id) =>{
  
    let data = {id, decr:'decr'}
   
    dispatch(qtyupdate(data))
  
  
  
  }

  const handleModalClick = (e, isvisible, id) =>{
    e.preventDefault()
    setCoupon(id)
    setVisible(isvisible)
    }

    useEffect(()=>{

        if(pathname === "/checkout"){
      
           return dispatch(viewCartshowModal(false))
          
          }
      
      
      })

      const exist = cartCoupons.findIndex((item) => item._id === currentProduct?._id);

if(loading) {

    return <Loader/>

}

    return (
        <div>
        <ScDetailWrap className="container my-5" style={{minHeight:"68vh"}}>
            <div className="row">
                <div className="col-sm-3 ">
      
   
                       <Image    
                        preview={false}               
                        style={{margin:'0px',height:"250px", width:"100%", display:'block',borderRadius: '.25rem'}}
                        src={ currentProduct?.image}
                        placeholder={<Loader/> }              
                        /> 

                </div>
               
                <div className="col-sm-9 pt-4">

                <Meta title={ currentProduct?.name} description={currentProduct?.description} />
                <div className="d-flex align-items-center justify-content-between pt-4">
               
           <h6>Price: &#x20B9; {currentProduct?.value}</h6>
           {/* <h6>Quantity:{ currentProduct?.quantity}</h6> */}
         
          </div>
        <div className="pt-3">
            
        {
              (exist === -1)?   
                      
                      <button  onClick={()=>onAdd(currentProduct)} className="btn "  style={{backgroundColor:'white', border:'1px solid #9a4c9d', color:'#9a4c9d'}}>
                      <MdAddShoppingCart className="addcart"/>&nbsp; 
                  
                    <small>Add Cart </small>

                    </button>   
                    :
                    <Fade> 
                    <Space>
                    <Button disabled={(exist !== -1) && cartCoupons[exist].qty === 1 ? true : false} type="primary" onClick={()=>decrement(currentProduct?._id)}>-</Button>
                    <Button>{(exist !== -1) && cartCoupons[exist].qty}</Button>
                    <Button type="primary" onClick={()=>increment(currentProduct?._id)}>+</Button>
       
                  </Space></Fade>      
                
           }
          {/* <PButton size="small"  type="primary" productId={currentProduct?.product && currentProduct?.product._id} total={currentProduct?.product && currentProduct?.product.value}  title={currentProduct?.product && currentProduct?.product.name}>shop</PButton>   */}
                </div>
            </div>
            </div>


            {/* <Button onClick={()=>dispatch(viewCartshowModal(true))}
         style={{backgroundColor:'white', border:'1px solid #9a4c9d ', color:'#9a4c9d', fontSize:"1rem", float:"right", clear:"left"}}
          type="primary">
          View Cart
       </Button> */}

       
            <Modal 
                width="45%"
                title="Terms and Conditions"
                footer={false}
                className=""
                visible={isVisible}
                onCancel={()=>setVisible(!isVisible)}>
                    <div className="px-4">
                    <Interweave content={curr_coupon?.terms }/>
                        </div>                

                </Modal> 
        </ScDetailWrap>
             
        </div>
    )
}




const ScDetailWrap = styled.div`

                    


.ant-card-body {
padding: 0px !important;
}
.voucher{

color:orange;
font-size:1.9rem;

}

.addcart{

    color:#9a4c9d;
    font-size:1.2rem;

}

.info{
    color:#9a4c9d;
    font-size:1rem;
}



hr{
    height:0.2rem;
    background-color:orange;
    width:50%;
    margin:0.2rem auto;
    
}

.action{
    display:flex;
    align-items:center;
}

.addCart{

    display:flex;
    align-items:center;
    justify-content:space-around;
     li{

         list-style:none;
         text-align:center;

     }


     
}
`