import React from 'react'
import { Form, Input, Button, Modal,  Checkbox } from 'antd';
import {loginSelector} from '../../../api/authSlice'
import {addUserCoupon} from '../../../api/authSlice'
import {useSelector, useDispatch} from 'react-redux'
import { useState } from 'react';
import { MdCardGiftcard } from "react-icons/md";

const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 17,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 7,
      span: 16,
    },
  };



export default function AddCoupon() {

const dispatch = useDispatch()
    const {isAuthenticate, curr_user} = useSelector(loginSelector)
    // const {isAuthenticate, user} = useSelector(addCouponSelector)
// console.log(curr_user.email);
    const [form] = Form.useForm();
   const [visible, setVisible] = useState(false)


    const onFinish = (values) => {

      if(isAuthenticate){
        let loginValues = {
          email: curr_user.email,
          coupon_id:values.coupon_id,
          islogin: true,
        }


      dispatch(addUserCoupon(loginValues));
    return  setVisible(false)
         
      }
      

              dispatch(addUserCoupon(values));
       return setVisible(false)

        // dispatch(showModal(false));

      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        form.resetFields()
      };

      const showModal = () => {
        setVisible(true);

      };
    
      const handleOk = e => {
    
        setVisible(false);
      };
    
      const handleCancel = e => {
      
        setVisible(false);

      };


    return (
<>
      { isAuthenticate && <Button 
      style={{backgroundColor:'white', border:'1px solid #9a4c9d', color:'#9a4c9d', fontSize:"1rem"}}
      icon={<MdCardGiftcard className="mr-1"/>}  type="primary" onClick={showModal}>
      Add Gift Voucher
    </Button> }

    <Modal
      title="Luova Gift Card "
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={false}
    >
<Form
      {...layout}
      name="basic"
      form={form}
      className="mt-2"
      initialValues={{ remember: true }}
      onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Coupon ID"
        name="coupon_id"
        rules={[{ required: true, message: 'Please enter Coupon Id!' }]}
      >
        <Input />
      </Form.Item>
      
{/* 
      {!isAuthenticate && 
<>
      <Form.Item
        name="email"
        label="E-mail"
        rules={[
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
          {
            required: true,
            message: 'Please input your E-mail!',
          },
        ]}
      >
          <Input />
      </Form.Item>


      <Form.Item
        name="password"
        label="New Password"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
        hasFeedback
      >
        <Input.Password />
      </Form.Item>

      <Form.Item
        name="confirm"
        label="Confirm Password"
        dependencies={['password']}
        hasFeedback
        rules={[
          {
            required: true,
            message: 'Please confirm your password!',
          },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }

              return Promise.reject('The two passwords that you entered do not match!');
            },
          }),
        ]}
      >
        <Input.Password />
      </Form.Item>

      </>
} */}
      <Form.Item {...tailLayout}>
      <button className="btn  float-right shadow-md"  style={{color:'#9a4c9d', backgroundColor:'#9a4c9d29', fontWeight:'500'}} type="primary" htmlType="submit">
          Submit
        </button>
      </Form.Item>
    </Form>
            
    </Modal>
    </>
    )
}
