import React, {useEffect, useState} from 'react'
import { fetchCurrentScoupons, scouponsSelector } from '../../api/sCouponSlice'
import { addCartCoupons, qtyupdate,  cartCouponSelector } from '../../api/cartSlice'
import { brandSelector, fetchOneBrand} from '../../api/brandSlice'
import Fade from 'react-reveal/Fade'
import {  useDispatch, useSelector } from 'react-redux';
import {useParams} from 'react-router-dom'
import styled from 'styled-components'
import { Table, Space, Button, Popover , Modal} from 'antd';
import Interweave from 'interweave'
import {viewCartshowModal } from '../../api/modalSlice'
import { MdCardGiftcard, MdAddShoppingCart } from "react-icons/md";
import { BsInfoCircleFill, BsInfoCircle} from "react-icons/bs";
import { Link } from 'react-router-dom';
import Loader from '../../shared/loader'

import CartView from '../../components/social_coupons/cartView'
import {useLocation} from 'react-router-dom'

export default function Scdetail(props) {
    const dispatch = useDispatch()
    let { id } = useParams();
    const { pathname } = useLocation()
    const {cartCoupons} = useSelector(cartCouponSelector)

    const {scatgoryCoupon , loading} = useSelector(scouponsSelector)
    const {currentBrand } = useSelector(brandSelector)
    const [isVisible, setVisible] = useState(false)
    const [curr_coupon, setCoupon] = useState(null);


console.log(currentBrand)
// console.log(isVisible)


useEffect(()=>{

 dispatch(fetchCurrentScoupons(id))
 dispatch(fetchOneBrand(id))

}, [dispatch])


const onAdd = (id, data) =>{
  
 console.log(id, data);

dispatch(addCartCoupons(data))

}


const increment = (id) =>{

    let data = {id, incr:'incr'}
  
    dispatch(qtyupdate(data))
    
  
  }
  
  const decrement = (id) =>{
  
    let data = {id, decr:'decr'}
   
    dispatch(qtyupdate(data))
  
  
  
  }

  const handleModalClick = (e, isvisible, id) =>{

    e.preventDefault()
    setCoupon(id)
    setVisible(isvisible)
    }

    useEffect(()=>{

        if(pathname === "/checkout"){
      
           return dispatch(viewCartshowModal(false))
          
          }
      
      
      })

const columns = [
  
{

    title:'E-voucher',
   
    render: (text, record)=>(

     <span className=" d-flex justify-content-start align-items-center  text-center text-dark">

         <MdCardGiftcard className="voucher p-1 bg-dark text-center"/>&nbsp; <small className="ml-2">{record.name}</small> </span>
    ),
    key:'E-voucher'
},
        {
            title: 'Value',
            dataIndex: 'value',
            render:(value)=>{
console.log({V:value});
           return <small>&#x20B9;&nbsp;{value}</small>
            },
            key: 'Value',
          },
          
        // {
        //     title: 'Validity',
        //     dataIndex: 'validity',
        //     key: 'Validity',
        //   },
    

          {
              tital:'Action',
              dataIndex:'Action',
              render:(text, record)=>{
           
                const exist = cartCoupons.findIndex((item) => item._id === record._id);
                return <div className="action">
                  {
                      (exist === -1)?   
                      <button  onClick={()=>onAdd(text, record)} className="btn "  style={{backgroundColor:'white', border:'1px solid #9a4c9d', color:'#9a4c9d'}}>
                      <MdAddShoppingCart className="addcart"/>&nbsp; 
                  
                        <small>Add Cart </small>

                    </button> : <Fade>  <Space>
                     <Button disabled={(exist !== -1) && cartCoupons[exist].qty === 1 ? true : false} type="primary" onClick={()=>decrement(record._id)}>-</Button>
                     <Button>{(exist !== -1) && cartCoupons[exist].qty}</Button>
                     <Button type="primary" onClick={()=>increment(record._id)}>+</Button>
        
                   </Space></Fade>
                
                  }
    
                    <h5 onClick={(e)=>handleModalClick(e, true, record)} className="text-secondary  m-0">
                    <BsInfoCircleFill className="ml-4 info " style={{cursor:"pointer"}} />                    
                    </h5>

                  {/* <Popover content={record.terms} title="Info" trigger="click">
                  <BsInfoCircleFill className="ml-4 info " style={{cursor:"pointer"}} />
                    </Popover> */}
                 </div> 

              }
          },

]


if(loading) {

    return <Loader/>

}

    return (
        <div>
        <ScDetailWrap className="container my-5" style={{minHeight:"70vh"}}>
            <div className="row">
                <div className="col-sm-3 ">
                   <div className="coupo shadow pb-0" style={{ borderRadius: '2rem'}}>
                   {/* <img src={currentBrand && currentBrand.image}  className="mx-auto p-1 d-block " style={{height:"140px", width:"100%", borderRadius: '0.25rem', display:'block', objectFit:'cover'}} alt="imglogo" /> */}

                   {
                     currentBrand && currentBrand.image? <img src={currentBrand && currentBrand.image}  className="mx-auto p-1 d-block " style={{height:"140px", width:"100%", borderRadius: '0.25rem', display:'block', objectFit:'cover'}} alt="imglogo" />
                      : 
                      <div className=" d-flex justify-content-center align-items-center text-center" style={{height:"140px", width:"100%",  display:'block',objectFit:'cover', borderRadius: '0.25rem' }}>
                         <MdCardGiftcard className="vouchercard text-center " style={{}}/>&nbsp;  
                         </div>                    
                    }

                  <h5 className="text-center text-light pt-1" style={{background:'#9a4c9d',height:"40px", borderRadius: '0 0 2rem 2rem'}}>{currentBrand && currentBrand.name}</h5>
                   </div>

                </div>
                <div className="col-sm-9">


                <Table columns={columns}
                
                expandable={{
                expandedRowRender: (record, index) =><div className="container px-5"> <p style={{ margin: 0 }}>{record.brand.description}</p></div>,
                    rowExpandable: record => record.name !== 'Not Expandable',
                  }}
                  rowKey={(record)=> record._id}
              
                dataSource={scatgoryCoupon } />

                </div>
            
            </div>


            <Button onClick={()=>dispatch(viewCartshowModal(true))}
         style={{backgroundColor:'white', border:'1px solid #9a4c9d ', color:'#9a4c9d', fontSize:"1rem", float:"right", clear:"left"}}
          type="primary">
          View Cart
       </Button>

       
            <Modal 
                width="45%"
                title="Terms and Conditions"
                footer={false}
                className=""
                visible={isVisible}
                onCancel={()=>setVisible(!isVisible)}>
                    <div className="px-4">
                    <Interweave content={curr_coupon?.brand?.terms }/>
                        </div>                

                </Modal> 
        </ScDetailWrap>
              
        </div>
    )
}




const ScDetailWrap = styled.div`

.voucher{

color:orange;
font-size:1.9rem;

}

.addcart{

    color:#9a4c9d;
    font-size:1.2rem;

}

.info{
    color:#9a4c9d;
    font-size:1rem;
}



hr{
    height:0.2rem;
    background-color:orange;
    width:50%;
    margin:0.2rem auto;
    
}

.action{
    display:flex;
    align-items:center;
}

.addCart{

    display:flex;
    align-items:center;
    justify-content:space-around;
     li{

         list-style:none;
         text-align:center;

     }


     
}

.vouchercard{

color:orange;
font-size:5rem;

}
`