import React from 'react'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade';
import {Link} from 'react-router-dom'
import {MdCardGiftcard} from 'react-icons/md'
import { Card, Row, Col } from 'antd';
import { Empty } from 'antd';
export default function Scoupon({brands, sAllCoupons}) {
    console.log(brands);


    return (
        <ScouponWrap className="container py-sm-3">
          <Row gutter={30}>

              {
                  brands.length == 0 ? <div className=""><Empty/></div> :
                  brands?.map((item, i)=>{

                    let arrLength = sAllCoupons && sAllCoupons.filter((it)=>{
                        return it?.brand?._id === item._id
                    })
                
                    // console.log({arrLength});

                    return <>{ 
                        arrLength && arrLength.length > 0 && <Col key={i} span={6} className="my-3" >
                           
                           <Fade duration={2000}>
                            <Link to={`/socialcoupon/${item._id}`}>
                    <div className="coupo shadow pb-0" style={{ borderRadius: '2rem',width:"255px"}}> 

                    {/* <MdCardGiftcard style={{fontSize:"5rem"}} className="mx-auto d-block text-warning" /> */}
                     {
                     item?.image? <img src={item?.image }  className="mx-auto d-block " style={{height:"140px", width:"100%", display:'block', objectFit:'cover',  margin:' 1.5rem', borderRadius: '1.5rem 1.5rem 0 0'}} alt="imglogo" />
                      : 
                      <div className=" d-flex justify-content-center align-items-center text-center" style={{height:"140px", width:"100%",  display:'block',objectFit:'cover',  margin:' 1.5rem 0', borderRadius: '1.5rem 1.5rem 0 0' }}>
                         <MdCardGiftcard className="voucher text-center " style={{}}/>&nbsp;  
                         </div>                    
                    }
                      
  

                   {/* <hr/> */}
               
                      <h6 className="text-center text-light pt-2" style={{background:'#9a4c9d',height:"40px", borderRadius: '0 0 2rem 2rem'}}>{item.name}</h6>

                    </div>
                    </Link>
                        </Fade>

                    </Col>
                    }
                    </>

                  })
              }
             
          </Row>

        </ScouponWrap>
    )
}

const ScouponWrap = styled.div`

hr{

    height:2px;
    border-bottom:2px solid white;
    /* border-style:dashed; */
    margin:0.5rem auto;
}

.load{

 text-align:center;
 margin:auto;
 display:block;

}

.voucher{

color:orange;
font-size:5rem;

}
`