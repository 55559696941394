import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form, Input, Button,message, Checkbox, InputNumber,  Row, Col  } from 'antd';
import banner from '../../images/contact.jpg'
import image from '../../images/image.jpg'
import styled from 'styled-components'
// import TextArea from 'rc-textarea';
import { PhoneOutlined , MailOutlined } from '@ant-design/icons';
import {loginSelector, ContactEmail } from '../../api/authSlice';



const layout = {
    labelCol: { span: 6},
    wrapperCol: { span: 18 },
  };

  const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
  };
  

export default function Contact() {

  // const {isAuthenticate, loading} = useSelector(loginSelector)
    const dispatch = useDispatch()
    

     const onFinishFailed = errorInfo => {
       console.log('Failed:', errorInfo);
     };
      
     
     const onFinish = values => {

      var numbers = /^[0-9]+$/;
      if(values.phone_no && !values.phone_no.match(numbers))
      {
     return message.error("Phone number must be only numbers")
        return true;
      }
      if(values.phone_no.length !== 10){
        message.error("Phone number must be 10 numbers")
      }
        else{
  
          dispatch(ContactEmail(values))
        };
      }

    return (

        <div style={{minHeight:"70vh"}}>
       <div style={{height:"400px",backgroundImage:`url('${banner}')`,backgroundRepeat:"no-repeat"}}>
       <ContactWrap>
         <div className="container flex">
                {/* <h1> Contact Us</h1> */}
                 {/* <h6> Contrary to popular belief, Lorem Ipsum is not simply random text.</h6> */}

         </div>
     </ContactWrap>
           
          </div>
  <div className="container flex py-5">
   <Row gutter={10}>
   <Col span={12} >
   <div className=" shadow mx-5 py-4 px-2 d-flex align-items-center justify-content-between flex-column" style={{width:"400px",height:"200px",borderRadius: '1rem'}}> 

      <PhoneOutlined style={{fontSize:"4rem" }} className="text-secondary pt-3"/>
      <h5>Phone: +91 8296099974</h5>
   </div>
</Col>


  <Col span={12} >
    <div className=" shadow mx-5 py-4 px-2 d-flex align-items-center justify-content-between flex-column" style={{width:"400px",height:"200px",borderRadius: '1rem'}}> 
    <MailOutlined style={{fontSize:"4rem" }} className="text-secondary pt-3"/>
        <h5 >Email: customercare@maalola.com</h5>
      
    </div>
  </Col>

    </Row>
  </div>
  <div className="flex py-5 pl-5">
    <Row gutter={10}>
    <Col span={12} >
        <Form
        {...layout}
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
    >

   <h2 className="py-3 text-center ">Contact Us</h2>

        <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: 'Please input your name!' }]}
        >
        <Input />
        </Form.Item>

        <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
        >
        <Input />
        </Form.Item>

        <Form.Item
        label="Phone Number"
        name="phone_no"
        rules={[{ required: true,  message: 'Please input your Phone number!' }]}
        >
        <Input />
        </Form.Item>

        <Form.Item
        label="Subject"
        name="subject"
        rules={[{ required: true,   message: 'Please input your Subject!'}]}
        >
        <Input.TextArea />
        </Form.Item>
    
        <Form.Item {...tailLayout}>
        <Button  style={{backgroundColor:'white', border:'1px solid #9a4c9d ', color:'#9a4c9d', fontSize:"1rem",float:"left"}}  type="primary" htmlType="submit">
            Submit
        </Button>
        </Form.Item>
        </Form>
        </Col>
      
      
       <Col span={12}>
           <div className="mx-5 py-5 px-2" style={{height:"400px",width:"600px",borderRadius: '0.25rem',backgroundImage:`url('${image}')`}}>
           </div>
       </Col>
   
       </Row>    
     </div>
   </div>

    )
}

const ContactWrap = styled.div`
position:absolute;
text-align:center;
padding:4rem 4rem;
`
